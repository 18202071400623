<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
         <el-form-item label="租户" prop="tenantId" :label-width="formLabelWidth">
            <el-select v-model="formInline.tenantId" clearable placeholder="请选择租户" >
              <el-option v-for="(item,index) in sysTenantList" :key="index" :label="item.tenantName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="名称" prop="orgName">
          <el-input v-model="formInline.orgName" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">搜索</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus"  @click="addHandle()">新增</el-button>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="租户" prop="tenantName" width="180"></el-table-column>
          <el-table-column label="类型" prop="orgType" width="100">
          <template slot-scope="{row}">
            <span v-if="row.orgType === 1" style="color:#009933">主机厂</span>
            <span v-if="row.orgType === 2" style="color:#cc0000">服务店</span>
          </template>
        </el-table-column>
        <el-table-column label="区域" prop="regionName" width="180">
           <template slot-scope="{row}">
            <span v-if="row.orgType === 1">全部区域</span>
            <span v-if="row.orgType === 2">{{row.regionName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="orgName" width="180"></el-table-column>
        <el-table-column label="简称" prop="nickName" width="200"></el-table-column>
        <el-table-column label="联系人" prop="contacts" width="150"></el-table-column>
        <el-table-column label="手机号" prop="mobile" width="150"></el-table-column>
        <!-- <el-table-column label="邮箱" prop="email" width="150"></el-table-column>
        <el-table-column label="地址" prop="address" width="200"></el-table-column> -->
        <el-table-column label="简介" prop="introduce" width="200"></el-table-column>
        <el-table-column label="状态" prop="status" width="75">
          <template slot-scope="{row}">
            <span v-if="row.status === 1" style="color:#009933">启用</span>
            <span v-if="row.status === 0" style="color:#cc0000">禁用</span>
          </template>
        </el-table-column>
       
        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="handelDetail(row)">详情</el-button>
            <el-button type="primary" size="mini" @click="handleEdit(row)">编辑</el-button>
            <el-button  type="primary" size="mini" @click="handleDelete(row)" style="background:#ff5722">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
          <el-form v-if="dialogStatus === 'detail'" ref='dataFormDetail' :model="dataForm" label-position="center">
          <el-form-item label="租户" prop="tenantName" :label-width="formLabelWidth">
            <el-input v-model="dataForm.tenantName" readonly></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="orgName" :label-width="formLabelWidth">
              <el-input v-if="dataForm.orgType === 1" v-model="orgTypeArray[0]" readonly></el-input>
              <el-input v-if="dataForm.orgType === 2" v-model="orgTypeArray[1]" readonly></el-input>
          </el-form-item>
          <el-form-item label="区域" prop="regionName" :label-width="formLabelWidth">
            <el-input v-if="dataForm.orgType === 1" v-model="allRegionText" readonly></el-input>
            <el-input v-if="dataForm.orgType === 2" v-model="dataForm.regionName" readonly></el-input>
          </el-form-item>
          <el-form-item label="名称" prop="orgName" :label-width="formLabelWidth">
            <el-input v-model="dataForm.orgName" readonly></el-input>
          </el-form-item>
          <el-form-item  label="简称" prop="nickName" :label-width="formLabelWidth">
            <el-input v-model="dataForm.nickName" readonly></el-input>
          </el-form-item>
          <el-form-item  label="联系人" prop="contacts" :label-width="formLabelWidth">
            <el-input v-model="dataForm.contacts" readonly></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile" :label-width="formLabelWidth">
            <el-input v-model="dataForm.mobile" readonly></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth">
            <el-input v-model="dataForm.email"   readonly></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address"  :label-width="formLabelWidth">
            <el-input type="textarea"  rows="2" v-model="dataForm.address"  readonly></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="introduce"  :label-width="formLabelWidth">
            <el-input type="textarea"  rows="2" v-model="dataForm.introduce"  readonly></el-input>
          </el-form-item>
          <!-- <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
            <el-switch v-model="whether"></el-switch>
          </el-form-item> -->
        </el-form>
        <el-form v-if="dialogStatus === 'edit' || dialogStatus === 'add'"  ref='dataForm' :model="dataForm" :rules="fromrules" label-position="center">
           <el-form-item label="租户" prop="tenantId" :label-width="formLabelWidth">
            <el-select v-model="dataForm.tenantId"  @change="tenantChanged"  clearable placeholder="请选择租户" >
              <el-option v-for="(item,index) in sysTenantList" :key="index" :label="item.tenantName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
            <el-form-item label="类型" prop="orgType" :label-width="formLabelWidth">
            <el-radio v-model="dataForm.orgType" @change="orgTypeClick"  :label="2">服务店</el-radio>
            <el-radio v-model="dataForm.orgType" @change="orgTypeClick"  :label="1">主机厂</el-radio>
          </el-form-item>
          <el-form-item label="区域" prop="regionId" :label-width="formLabelWidth">
            <el-select v-model="dataForm.regionId" clearable placeholder="请选择区域" >
              <el-option v-for="(item,index) in regionList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" prop="orgName" :label-width="formLabelWidth">
            <el-input v-model="dataForm.orgName" @input="e => dataForm.orgName= validForbid(e)" show-word-limit maxlength="30"   style="padding:0 48px 0 0"   placeholder="请输入名称"></el-input>
          </el-form-item>

          <el-form-item label="简称" prop="nickName" :label-width="formLabelWidth">
            <el-input v-model="dataForm.nickName" placeholder="请输入简称" @input="e => dataForm.nickName= validForbid(e)" show-word-limit maxlength="50"  style="padding:0 48px 0 0"   ></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts" :label-width="formLabelWidth">
            <el-input v-model="dataForm.contacts" @input="e => dataForm.contacts= validForbid(e)" show-word-limit maxlength="20"   style="padding:0 48px 0 0"  placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile" :label-width="formLabelWidth">
            <el-input v-model="dataForm.mobile" maxlength="11"   style="padding:0 48px 0 0"  placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth">
            <el-input v-model="dataForm.email"   style="padding:0 48px 0 0"  placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address"  :label-width="formLabelWidth">
            <el-input type="textarea"  rows="2" v-model="dataForm.address"  show-word-limit maxlength="100"  style="padding:0 48px 0 0"   placeholder="请输入地址"></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="introduce"  :label-width="formLabelWidth">
            <el-input type="textarea"  rows="2" v-model="dataForm.introduce"  show-word-limit maxlength="100"  style="padding:0 48px 0 0"   placeholder="请输入简介"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
            <el-switch v-model="whether"></el-switch>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'add' ? addClick('dataForm') : editClick('dataForm')">
              立即提交
            </el-button>
            <el-button  v-if="dialogStatus === 'add'"   @click="resetForm('dataForm')">
              重置
            </el-button>
             <el-button  v-if="dialogStatus === 'edit'"  :disabled="true"   @click="resetForm('dataForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { orgData, orgSearch,tenantList, regionListAll,orgDetail, orgAdd , orgEdit , orgDel } from '@/api/api.js'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      formInline: {
        orgName: '',
        tenantId: ''
      },
      dataForm: {
              id: '',
        tenantId: '',
      tenantName: '',
        orgType: '',
      regionId: '',
     regioName: '',
        orgName: '',
        nickName: '',
        contacts: '',
        mobile: '',
        email: '',
        address: '',
        introduce: '',
        status: 1
      },
      dialogFormVisible: false,
      formLabelWidth: '100px',
      dialogStatus: '',
      textMap: {
        edit: '编辑机构',
        add: '新增机构'
      },
      whether: false,
      sysTenantList: [],
      regionList: [],
      resultList: [],
      orgTypeArray: ["主机厂","服务店"],
      allRegionText: '全部区域',
      pagesize: 10,
      currentPage: 1,
      total: 0,
      fromrules: {
        tenantId: [{ required: true, message: '请选择租户', trigger: 'blur' }],
        orgType:  [{ required: true, message: '请选择类型', trigger: 'blur' }],
        regionId: [{ required: true, message: '请选择区域', trigger: 'blur' }],
        orgName: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        mobile: [{ pattern: /^1\d{10}$/, message: '请输入正确的手机格式' }],
        email: [{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        tenantId: this.formInline.tenantId,
        orgNameKeywords: this.formInline.orgName
      }
      orgData(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 搜索
    onSubmit () {
      this.currentPage=1
      var params ={
        page: this.currentPage,
        limit: this.pagesize,
              tenantId: this.formInline.tenantId,
        orgNameKeywords: this.formInline.orgName
      }
      orgSearch(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
     // 租户列表
    getSysTenantList () {
      tenantList().then(res=>{
        this.sysTenantList = res.data.data
      })
    },
     // 区域列表
    getRegionList (tenantId) {
      var params = new URLSearchParams()
      params.append('tenantId', tenantId)
      regionListAll(params).then(res=>{
        this.regionList = res.data.data
      })
    },
    resetRegionList(){
     this.regionList = [];
     var allRegion= {"id":0,"name":"全部区域"};
     this.regionList.push(allRegion);

    },
   tenantChanged(value){
      if(value!=''){
        this.dataForm.regionId=''
        this.getRegionList(value)
      }
    },
    orgTypeClick(value){
        this.dataForm.regionId=''
      if(value==1){
        this.resetRegionList()
      }else{
        this.regionList=[]
        if(this.dataForm.tenantId!=''){
          this.getRegionList(this.dataForm.tenantId)
        }
      
      }
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      this.$refs.dataForm.resetFields()
    },
    // 重置
    resetForm (dataForm) {
       this.resetTemp()
      if (this.$refs[dataForm].resetFields() !== undefined) {
        this.$refs[dataForm].resetFields()
      }
      this.whether=true
    },
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()

      }
      
      this.dataList()
    },
    resetTemp () {
      this.dataForm = {
            id: '',
       tenantId: '',
     tenantName: '',
       orgType: 2,
      regionId: '',
      regioName: '',
        orgName: '',
        nickName: '',
        contacts: '',
        mobile: '',
        email: '',
        address: '',
        introduce: '',
        status: 1
      }
      this.whether=true
    },
    // 新增
    addHandle () {
      this.resetTemp()
      this.dialogStatus = 'add'
      this.dialogFormVisible = true
    },
    whetherState () {
      if (this.whether === false) {
        this.dataForm.status = '0'
      } else {
        this.dataForm.status = '1'
      }
    },
    addClick (dataForm) {
      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          this.whetherState()
          var params = new URLSearchParams()
          params.append('tenantId', this.dataForm.tenantId)
          params.append('orgType', this.dataForm.orgType)
          params.append('regionId', this.dataForm.regionId)
          params.append('orgName', this.dataForm.orgName)
          params.append('nickName', this.dataForm.nickName)
          params.append('contacts', this.dataForm.contacts)
          params.append('mobile', this.dataForm.mobile)
          params.append('email', this.dataForm.email)
          params.append('address', this.dataForm.address)
          params.append('introduce', this.dataForm.introduce)
          params.append('status', this.dataForm.status)

          orgAdd(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else{
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('请完善机构信息')
        }
      })
    },
    // 详情
    handelDetail (row) {
      this.resetTemp()
      this.dataForm = Object.assign({}, row)
    
      this.dialogFormVisible = true
      this.dialogStatus = 'detail'
    },
    // 编辑
    handleEdit (row) {
    
      if(row.orgType==1){
        this.resetRegionList()
      }else{
           this.getRegionList(row.tenantId)
      }
      this.resetTemp()
      this.dataForm = Object.assign({}, row)
      if(this.dataForm.status=='1'){
        this.whether=true
      }else{
        this.whether=false
      }
      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
    },
    editClick (dataForm) {
      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          this.whetherState()
          var params = new URLSearchParams()
          params.append('id', this.dataForm.id)
          params.append('tenantId', this.dataForm.tenantId)
          params.append('orgType', this.dataForm.orgType)
          params.append('regionId', this.dataForm.regionId)
          params.append('orgName', this.dataForm.orgName)
          params.append('nickName', this.dataForm.nickName)
          params.append('contacts', this.dataForm.contacts)
          params.append('mobile', this.dataForm.mobile)
          params.append('email', this.dataForm.email)
          params.append('address', this.dataForm.address)
          params.append('introduce', this.dataForm.introduce)
          params.append('status', this.dataForm.status)
          orgEdit(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                message: res.data.msg,
                type:'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else {
              if(res.data.code === 404){
                this.$message.error('系统出现异常，更新失败')
              }else{
                this.$message.error(res.data.msg)
              }
            }
          })
        } else {
          this.$message.error('请完善机构信息')
        }
      })
    },
    // 删除
    handleDelete (row) {
      var _this= this
      this.$confirm('确定刪除 ' + row.orgName + '?', '信息提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        orgDel(row.id).then(res => {
          if(res.data.code === 100){
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            
             if(this.resultList!=null&&this.resultList.length==1){
              this.currentPage =this.currentPage-1
            }
            this.dataList()
          }
        })
      }).catch((error) => {
        _this.$message.error('删除失败')
      })
    }
  },
  mounted () {
    this.dataList()
    this.getSysTenantList()
  }
}
</script>
